import { pipe } from "ramda";

import {
  captureAbTestingException,
  getAbTestFeature,
} from "@/lib/ab-testing/client";
import { url } from "@/lib/ab-testing/service/attributes";
import { redirectToUrl } from "@/lib/ab-testing/service/helpers";
import { applyArgusUuidToUrl } from "@/lib/argus/client/thinClient";
import { extendUrlParams } from "@/lib/helpers/url";

function splitUrlFeature(redirect = redirectToUrl) {
  const splitUrl = getAbTestFeature<string>("split-url");
  if (!splitUrl) {
    return;
  }
  const currentPage = url();

  if (currentPage === splitUrl) {
    return;
  }
  pipe(applyArgusUuidToUrl, extendUrlParams, redirect)(splitUrl);
}

function splitUrlMapFeature(redirect = redirectToUrl) {
  const splitUrlMap = getAbTestFeature<Record<string, string>>("split-url-map");

  if (!splitUrlMap) {
    return;
  }

  const currentPage = url();

  if (Object.values(splitUrlMap).includes(currentPage)) {
    return;
  }

  const target = splitUrlMap[currentPage];
  if (!target) {
    const error = new Error(
      `Tried to run split url test but the currentPage was not included in the test: ${currentPage}`,
    );
    captureAbTestingException(error);
    return error;
  }

  pipe(applyArgusUuidToUrl, extendUrlParams, redirect)(target);
  return target;
}

export { splitUrlFeature, splitUrlMapFeature };
